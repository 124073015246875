import React from "react";
import styles from "./privacyPolicy.module.css"; // Importing CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyContainerr}>
      <h1 className={styles.title}>Privacy Policy for Website to App</h1>
      <p className={styles.date}>Effective Date: 18-09-2024</p>

      <h2 className={styles.title}>1. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li>
          <strong>Website URLs:</strong> When you add a website to be rendered
          as an app, we store the website URL in order to provide the requested
          service. This URL is not shared with third parties or used for any
          other purpose.
        </li>
        <li>
          <strong>Usage Data:</strong> We may collect information about how you
          interact with the app, including the websites you add, the duration of
          app usage, and app performance metrics. This data helps us improve the
          functionality and user experience of "Website to App."
        </li>
        <li>
          <strong>Device Information:</strong> We may collect information about
          the device you are using, including its operating system, IP address,
          device identifiers, and browser type, to enhance app compatibility and
          performance.
        </li>
      </ul>

      <h2 className={styles.title}>2. How We Use Your Information</h2>
      <p>The information we collect is used to:</p>
      <ul>
        <li>Render the website you provide in an app-like format.</li>
        <li>Improve app performance and user experience.</li>
        <li>Monitor usage patterns to enhance the features of the app.</li>
        <li>Respond to your inquiries and provide customer support.</li>
      </ul>

      <h2 className={styles.title}>3. Third-Party Services</h2>
      <p>
        Your use of "Website to App" may involve the loading and rendering of
        third-party websites. Please note that these third-party websites have
        their own privacy policies and practices, which are independent of ours.
        We encourage you to review the privacy policies of the websites you
        choose to access through our app.
      </p>
      <p>
        We do not share your personal information with any third-party
        advertisers or marketing services.
      </p>

      <h2 className={styles.title}>4. Security</h2>
      <p>
        We take reasonable measures to protect your information from
        unauthorized access, use, or disclosure. However, no system can be
        completely secure, and we cannot guarantee the absolute security of your
        data.
      </p>

      <h2 className={styles.title}>5. Children's Privacy</h2>
      <p>
        "Website to App" is not intended for children under the age of 13, and
        we do not knowingly collect personal information from children. If we
        become aware that we have collected information from a child under 13,
        we will take steps to delete that information.
      </p>

      <h2 className={styles.title}>6. Your Rights</h2>
      <p>As a user, you have the right to:</p>
      <ul>
        <li>Request access to the information we collect about you.</li>
        <li>
          Request corrections to your data if it is inaccurate or incomplete.
        </li>
        <li>Request deletion of your data.</li>
      </ul>
      <p>
        To exercise these rights, please contact us using the contact details
        provided below.
      </p>

      <h2 className={styles.title}>7. Changes to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any significant changes by posting the new Privacy Policy on this
        page and updating the "Effective Date" above. We encourage you to review
        this Privacy Policy periodically for any updates.
      </p>

      <h2 className={styles.title}>8. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, or if you wish to
        exercise your rights regarding your data, please contact us at:
      </p>
      <p>Email: sondhwebcreators@gmail.com</p>
      <p>Address: Sondh Web Creators, Amritsar, Punjab, India</p>
    </div>
  );
};

export default PrivacyPolicy;
