import React from "react";
import styles from "./termsAndConditions.module.css"; // Import the CSS module

const TermsAndConditions = () => {
  return (
    <div className={styles.termsContainer}>
      <h1 className={styles.title}>Terms and Conditions for Website to App</h1>
      <p className={styles.date}>Effective Date: 18-09-2024</p>

      <h2 className={styles.sectionTitle}>1. Acceptance of Terms</h2>
      <p>
        By downloading, installing, or using the "Website to App" app, you agree
        to comply with and be bound by these Terms and all applicable laws. If
        you do not agree with these Terms, you may not access or use the app.
      </p>

      <h2 className={styles.sectionTitle}>2. Service Description</h2>
      <p>
        The "Website to App" app allows users to input website URLs and render
        them in an app-like format for enhanced user experience. This app merely
        provides an interface for users to interact with third-party websites
        and does not control or take responsibility for the content hosted on
        those websites.
      </p>

      <h2 className={styles.sectionTitle}>3. User Conduct</h2>
      <p>
        You agree to use the Service only for lawful purposes and in accordance
        with these Terms. You agree not to:
      </p>
      <ul>
        <li>
          Use the Service for any illegal activities or in violation of any
          applicable laws.
        </li>
        <li>
          Submit or link to any harmful, unlawful, or inappropriate content
          through the app.
        </li>
        <li>
          Misuse, interfere with, or disrupt the Service or any of the
          associated systems, servers, or networks.
        </li>
        <li>
          Use the Service to infringe on the rights of others, including but not
          limited to intellectual property rights, privacy rights, or
          proprietary rights.
        </li>
      </ul>

      <h2 className={styles.sectionTitle}>4. Third-Party Websites</h2>
      <p>
        The app allows you to interact with third-party websites. We are not
        responsible for the availability, content, or practices of these
        websites. By using our Service, you acknowledge that you access these
        websites at your own risk, and their respective terms of service and
        privacy policies govern your interaction with them.
      </p>
      <p>
        We are not responsible for any content that you may encounter through
        third-party websites rendered within the app, including but not limited
        to any inappropriate or harmful materials.
      </p>

      <h2 className={styles.sectionTitle}>5. Modifications to the Service</h2>
      <p>
        We reserve the right to modify or discontinue, temporarily or
        permanently, the Service (or any part of it) with or without notice. You
        agree that we will not be liable to you or any third party for any
        modification, suspension, or discontinuance of the Service.
      </p>

      <h2 className={styles.sectionTitle}>6. Termination</h2>
      <p>
        We may terminate or suspend access to the Service immediately, without
        prior notice or liability, if you breach these Terms or if we are
        required to do so by law. Upon termination, your right to use the
        Service will cease immediately.
      </p>

      <h2 className={styles.sectionTitle}>7. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, in no event shall we, our
        directors, employees, partners, or affiliates be liable for any
        indirect, incidental, special, consequential, or punitive damages,
        including, without limitation, loss of profits, data, use, or goodwill,
        arising out of or related to your use of the Service, regardless of
        whether such damages were foreseeable or whether we had been advised of
        the possibility of such damages.
      </p>

      <h2 className={styles.sectionTitle}>8. Disclaimer of Warranties</h2>
      <p>
        The Service is provided "as is" and "as available" without any
        warranties, either express or implied, including, but not limited to,
        implied warranties of merchantability, fitness for a particular purpose,
        or non-infringement. We make no representations or warranties regarding
        the availability, reliability, or accuracy of the Service.
      </p>

      <h2 className={styles.sectionTitle}>9. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless [Your Company Name], its
        affiliates, and its employees, officers, and directors from any claims,
        liabilities, damages, losses, or expenses, including reasonable legal
        fees, arising out of or in connection with your use of the Service, your
        breach of these Terms, or your violation of any law or third-party
        rights.
      </p>

      <h2 className={styles.sectionTitle}>10. Intellectual Property</h2>
      <p>
        All intellectual property rights related to the "Website to App" app,
        including but not limited to trademarks, logos, and copyrighted
        materials, are owned by [Your Company Name]. You may not copy, modify,
        distribute, sell, or lease any part of the Service or its related
        content without our prior written consent.
      </p>

      <h2 className={styles.sectionTitle}>11. Changes to These Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. We will notify
        you of any significant changes by posting the updated Terms in the app
        and updating the "Effective Date" above. By continuing to use the
        Service after any modifications, you agree to be bound by the updated
        Terms.
      </p>

      <h2 className={styles.sectionTitle}>12. Governing Law</h2>
      <p>
        These Terms and Conditions are governed by and construed in accordance
        with the laws of [Your Country/State], without regard to its conflict of
        law provisions. You agree to submit to the personal jurisdiction of the
        courts in [Your Jurisdiction] for any disputes related to the Service.
      </p>

      <h2 className={styles.sectionTitle}>13. Contact Us</h2>
      <p>
        If you have any questions about these Terms and Conditions, please
        contact us at:
      </p>
      <p>Email: sondhwebcreators@gmail.com</p>
      <p>Address: Sondh Web Creators, Amritsar, Punjab, India</p>
    </div>
  );
};

export default TermsAndConditions;
