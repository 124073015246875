import "./App.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TermsAndConditions from "./Pages/App/Pstet/TermsAndConditions";
import ErrorPage from "./Pages/ErrorPage";
import HomePage from "./Components/HomePage";
import PrivacyPolicy from "./Pages/App/Pstet/PrivacyPolicy";
import SondhPrivacyPolicy from "./Pages/Website/SondhWebCreators/PrivacyPolicy";
import SondhTermsAndConditions from "./Pages/Website/SondhWebCreators/TermsAndConditions";
import PrivacyPolicyWebsiteToApp from "../src/Pages/App/websiteToApp/privacyPolicy";
import TermsAndConditonsWebsiteToApp from "../src/Pages/App/websiteToApp/termsAndConditions";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/App/pstet/termsandconditions",
      element: <TermsAndConditions />,
    },
    {
      path: "/App/pstet/privacypolicy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/privacypolicy",
      element: <SondhPrivacyPolicy />,
    },
    {
      path: "/termsandconditions",
      element: <SondhTermsAndConditions />,
    },
    //  Website to app
    {
      path: "/app/websitetoapp/privacypolicy",
      element: <PrivacyPolicyWebsiteToApp />,
    },
    {
      path: "/app/websitetoapp/termandconditions",
      element: <TermsAndConditonsWebsiteToApp />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
